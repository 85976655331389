import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';

export const table = css`
  width: 100%;
  font-family: var(--font-family-primary);
`;

export const head = css`
  text-align: left;
  font-size: var(--font-size-micro);
  line-height: var(--line-height-micro);
  color: hsl(var(--color-textLight-7));
  letter-spacing: 1.15px;
  text-transform: uppercase;
  font-weight: var(--font-weight-regular);
  padding: 0;
`;

export const row = css`
  border-bottom: hsl(var(--color-borderUltraLight-9)) 1px solid;
  &:hover {
    background-color: hsl(var(--color-backgroundLight-10));
  }
`;

export const data = css`
  padding: ${spacing(16)} ${spacing(45)} ${spacing(16)} 0;
`;

export const link = css`
  font-weight: var(--font-weight-bold);
`;

export const active = css`
  ${link}
  &:hover {
    text-decoration: underline;
  }
`;

export const action = css`
  text-align: right;

  button:hover {
    path {
      fill: hsl(var(--color-textLight-7));
    }
  }
`;

export const button = css`
  border: hsl(var(--color-borderUltraLight-9)) 1px solid;
  border-radius: var(--border-radius-full);

  height: ${spacing(15)};
  width: ${spacing(15)};
  padding: ${spacing(10)};
  box-sizing: content-box;
  transition: all ease-in-out 0.3s;

  & > path {
    fill: hsl(var(--color-text-1)) !important;
  }

  &:hover {
    border: hsl(var(--color-borderBlack-2)) 1px solid;
  }
`;

export const graph = css`
  min-width: 500px;
`;

export const pill = css`
  ${font.size.tiny};
  color: #ffffff;
  padding: ${spacing(2)} ${spacing(4)};
  border-radius: ${spacing(4)};
  pointer-events: none;
  text-transform: capitalize;
`;

export const successPill = css`
  ${pill};
  background: hsl(var(--color-textSuccess-7));
`;

export const errorPill = css`
  ${pill};
  background: hsl(var(--color-warning-6));
`;
